<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div v-if="displaySubQuoteImage">
          <h6 class="title is-6">Add Image(s) From Additional</h6>
          <div class="field">
            <span v-for="quote in subQuoteNos"
              :key="quote.key">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input :id="`quote${quote.key}`"
                  type="checkbox"
                  :value="quote"
                  v-model="selectedQuotes"
                  checked="checked">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>{{ quote.key === 0 ? 'Quote' : `Add ${quote.key}` }}</label>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="title">Images</p>
            <p class="subtitle">{{ subtitle }} <span :class="{ 'has-text-danger' : selectedCount > maxSelected }">({{ selectedCount }}/{{ imagesWithInfo.length }})</span></p>
          </div>
          <div class="column is-narrow">
            <div class="buttons">
              <a class="button tooltip"
                @click="getThumbnails"
                data-tooltip="Refresh">
                <span class="icon">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a>
              <a class="button tooltip"
                @click="setSelectedStatusAll(true)"
                data-tooltip="Select all">
                <span class="icon has-text-success">
                  <i class="mdi mdi-select-all mdi-24px" />
                </span>
              </a>
              <a v-show="hasSelected"
                class="button tooltip"
                @click="setSelectedStatusAll(false)"
                data-tooltip="Deselect all">
                <span class="icon has-text-grey">
                  <i class="mdi mdi-select-off mdi-24px" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div v-if="isLoadingImages"
          class="columns is-multiline">
          <div class="section">
            <div class="is-loading-image-popup content has-text-grey has-text-centered" />
          </div>
        </div>
        <div v-else-if="imagesWithInfo.length ===0">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>No Images</p>
            </div>
          </section>
        </div>
        <div v-else
          class="columns is-multiline">
          <div v-for="(image, index) in imagesWithInfo"
            :key="index"
            class="column is-narrow p-2">
            <div v-if="image.imageContent"
              class="card"
              style="box-shadow: 0 0 0 1px #d9d9d9">
              <header class="card-header">
                <!-- <p class="card-header-title">
                  Image title
                </p> -->
                <!-- <p class="card-header-title"
                  style="justify-content: flex-end"> -->
                <div class="card-header-title has-background-grey-lighter">
                  <div class="columns is-gapless"
                    style="width: 100%">
                    <div class="column">
                      <div class="field">
                        <div class="control">
                          <div class="pretty p-icon p-curve p-smooth"
                            style="font-size: 0.875rem">
                            <input :id="`chk-pretty-image1${index}`"
                              :name="`chk-pretty-select-image${index}`"
                              v-model="image.isSelected"
                              type="checkbox">
                            <div class="state p-success">
                              <i class="icon mdi mdi-check" />
                              <label />
                            </div>
                          </div>
                          <!-- <input class="is-checkradio is-small is-success"
                            :class="{ 'has-background-color': image.isSelected }"
                            :id="`chk-image-select${index}`"
                            :name="`chk-image-select${index}`"
                            type="checkbox"
                            v-model="image.isSelected">
                          <label :for="`chk-image-select${index}`"
                            data-label-empty /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-image">
                <a @click="viewImage(index)">
                  <figure class="image is-96x96">
                    <img v-if="image"
                      class="image is-96x96"
                      style="object-fit: cover"
                      :src="image.imageContent"
                      :key="image.quoteImageId">
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <bulma-carousel-modal v-if="isCarouselModalActive"
      :image-width="800"
      :image-height="600"
      :active.sync="isCarouselModalActive"
      :image-ids="imageIds"
      :start-index="carouselStartIndex"
      @close="closeCarousel()" />
  </div>
</template>

<script>
import ImageSelectService from './ImageSelectService'
import { BulmaCarouselModal } from '@/components/BulmaCarousel'

const MaximumImages = 99

export default {
  name: 'ImageSelect',
  components: {
    BulmaCarouselModal
  },
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    quoteIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxSelected: {
      type: Number,
      default: MaximumImages
    },
    subtitle: {
      type: String,
      default: 'Select images to attach'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    subQuoteNos: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      size: 200,
      imagesWithInfo: [],
      imageIds: null,
      isConfirmModalActive: false,
      selectedImageId: '',
      queuedFileCount: 0,
      isCarouselModalActive: false,
      carouselStartIndex: 0,
      innerValue: [],
      isUpdateSelected: false,
      isLoadingImages: false,
      selectedImages: [],
      selectedQuotes: [],
      allImages:[]
    }
  },
  computed: {
    hasSelected() {
      return this.imagesWithInfo.some(i => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function(total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    },
    subtitleText() {
      if (this.maxSelected < MaximumImages) {
        if (this.subtitle.includes('<max>')) {
          return `${this.subtitle.replace('<max>', `${this.maxSelected}`)}`
        } else {
          return `${this.subtitle} (${this.maxSelected} images)`
        }
      } else {
        return this.subtitle
      }
    },
    selected() {
      if (this.imagesWithInfo) {
        return this.imagesWithInfo.map(function(i) {
          return i.isSelected
        })
      }
    },
    displaySubQuoteImage()
    {
      return this.subQuoteNos.length > 1 ? true : false
    }
  },
  watch: {
    selected(newVal, oldVal) {
       if (this.imagesWithInfo) {
      const selectedImages = this.imagesWithInfo
        .filter(i => i.isSelected)
        .map(function(i) {
          return {
            quoteImageId: i.quoteImageId,
            fileName: i.fileName
          }
        })
      this.$emit('input', selectedImages)
       }
    },
    isLoadingImages(newVal, oldVal) {
      this.$emit('update:loading-images', newVal)
    },
    selectedQuotes: function (newVal, oldVal) {
      if(this.displaySubQuoteImage)
        this.getSelectedQuoteImages()
    }
  },
  created() {
    this.selectedImages = this.value
    this.getThumbnails()
    this.selectedQuotes = this.subQuoteNos
    // this.imagesSelected = new Array(this.value.length)
  },
  mounted() {},
  methods: {
    // async getThumbnail(id, index) {
    //   var thumbnail = await ImageSelectService.getImageThumbnail(id, this.size)
    //   this.images.splice(index, 1, thumbnail)
    // },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await ImageSelectService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
      // this.imagesWithInfo.splice(index, 1, thumbnail)
    },
    async getThumbnails() {
      this.isLoadingImages = true
      const vm = this
      if (this.quoteIds.length === 0) {
        this.imageIds = await ImageSelectService.getImageIds(this.quoteId)
      } else {
        this.imageIds = []
        const idPromises = this.quoteIds.map(function(id) {
          return ImageSelectService.getImageIds(id)
        })
        const results = await Promise.all(idPromises)
        this.imageIds = [].concat.apply([], results)
      }
      // this.images = new Array(this.imageIds.length)
      // this.imagesWithInfo = new Array(this.imageIds.length)
      // this.initSelectedIds(this.imageIds.length)
      const promises = this.imageIds.map(function(id, index) {
        // vm.getThumbnail(id, index)
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.allImages = this.imagesWithInfo
      // Select previouly selected images
      this.imagesWithInfo.forEach(function(i) {
        if (vm.selectedImages.some(s => s === i.quoteImageId)) {
          i.isSelected = true
        }
        if (vm.isAudanet) {
          i.isSelected = i.isSelected || i.referenceId === ''
        }
      })
      this.isLoadingImages = false
    },
    setSelectedStatusAll(selected) {
      this.imagesWithInfo.forEach(i => (i.isSelected = selected))
    },
    async viewImage(index) {
      this.$showSpinner()
      this.carouselStartIndex = index
      this.isCarouselModalActive = true
      this.$hideSpinner()
    },
    closeCarousel() {
      this.isCarouselModalActive = false
    },
    getSelectedQuoteImages()
    {
      if(this.allImages.length !== 0)
      {
        const selectedQuoteImages = []
        for (var key in this.selectedQuotes) {
           for (let i = 0; i < this.allImages.length; i++) {
              const currentImage = this.allImages[i]
            if(currentImage.quoteId === this.selectedQuotes[key].value)
              selectedQuoteImages.push(this.allImages[i])
          }
        }
        this.imagesWithInfo = selectedQuoteImages
        this.setSelectedStatusAll(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header-title {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem !important;
}
</style>
